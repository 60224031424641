.react-switch-checkbox {
  @apply h-0 w-0 hidden;
}

.react-switch-label {
  @apply bg-gray-300 flex items-center justify-between cursor-pointer rounded transition-all relative;
  height: 24px;
  width: 44px;
}

.react-switch-label.active {
  background: #28cd41;
}

.react-switch-label .react-switch-button {
  @apply absolute rounded bg-white transition-all;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  content: '';
  height: 20px;
  left: 2px;
  top: 2px;
  width: 20px;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 40px;
}

.ripple {
  @apply h-full bg-gray-200;
  width: 2px;
}
